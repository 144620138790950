:root{
  --rich-black:#0D1821;
  --prussian-blue:#213144;
  --indigo-dye:#344966;
  --powder-blue:#B4CDED;
  --baby-powder:#F0F4EF;
  --sage:#BFCC94;
}

.App {
  background-color: var(--rich-black);
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  background-color: var(--prussian-blue);
  width: 100% - 50px;
  padding-left: 50px;
  display: flex;
  flex-flow: column;
  align-items: start;
}

.App-body {

}

button {
  margin: 16px;
  padding: 16px;
  background-color: var(--prussian-blue);
  border: solid var(--baby-powder) 1px;
  border-radius: 2px;
  font-size: 24px;
  color: white;
}